import React, { useState } from "react";
import { Button } from "../../elements/Button";
import { Input } from "../../elements/Input";
import { Flex } from "../../elements/Flex";
import config from "../../config";
import env from "../../env";
import axios from "axios";
import { useConfig } from "@genflow/core";
import { CgDanger } from "react-icons/cg";
import { Text } from "../../elements/Text";
import { GoCheck } from "react-icons/go";

export default () => {
  const { vendorUuid } = useConfig();
  const { verificationsBaseURL } = config[env];
  const [revealInput, setRevealInput] = useState(false);
  const [emailAddress, setEmailAddress] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [verified, setVerified] = useState(null);

  const onVerify = async (emailAddress) => {
    // Commented out as requires CORS, using hardcoded production base URL
    // const url =`${verificationsBaseURL}/verify/email/${vendorUuid}/STUDENT_VERIFICATION/${emailAddress}`;
    const url = `https://x0bkits8j3.execute-api.eu-west-1.amazonaws.com/prod/verify/email/${vendorUuid}/STUDENT_VERIFICATION/${emailAddress}`;

    setVerifying(true);
    try {
      const response = await axios.get(url);

      console.log("response", response);

      if (response?.statusCode !== 200) {
        setVerified(true);
      }
    } catch (e) {
      console.log(e);
      setVerified(false);
    } finally {
      setVerifying(false);
    }
  };

  console.log("verified", verified);
  if (verified) {
    return (
      <Flex
        bg="white"
        p={2}
        textAlign="center"
        borderRadius="5px"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <GoCheck color="teal" size={25} />
        <Text mt={2} color="teal" fontWeght={600}>
          Congratulations! Check your email at {emailAddress} for your discount code!
        </Text>
      </Flex>
    );
  }

  // verified === null when user has not performed a verification
  if (verified === false) {
    return (
      <Flex
        bg="white"
        p={2}
        textAlign="center"
        borderRadius="5px"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <CgDanger color="#ca4c6d" size={25} />
        <Text mt={2} color="#ca4c6d" fontWeght={600}>
          Unfortunately we could not verify you at this email, please make sure you use your student email address!
        </Text>
      </Flex>
    );
  }

  return revealInput ? (
    <Flex>
      <Input type="email" placeholder="lilly@student.com" onChange={(e) => setEmailAddress(e.target.value)} />
      <Button
        className={verifying ? "disabled" : ""}
        variant="darkTeal"
        onClick={() => !verifying && onVerify(emailAddress)}
      >
        Verify
      </Button>
    </Flex>
  ) : (
    <Button
      variant="darkTeal"
      mx={["auto", null, "0"]}
      onClick={() => {
        setRevealInput(true);
      }}
    >
      GET MY STUDENT DISCOUNT CODE
    </Button>
  );
};
