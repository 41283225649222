import styled from "styled-components";
import { space, layout, color, grid, background, border, position, typography } from "styled-system";

export const Grid = styled.div`
  display: grid;
  ${space};
  ${layout};
  ${color};
  ${grid};
  ${background};
  ${border};
  ${position};
  ${typography};
`;
