import React from "react";
import { Button } from "../elements/Button";
import { Flex } from "../elements/Flex";
import { Grid } from "../elements/Grid";
import { Text } from "../elements/Text";
import { Heading } from "../elements/Heading";
import { Box } from "../elements/Box";
import { CgArrowLongRight } from "react-icons/cg";
import css from "@styled-system/css";
import { Image } from "../elements/Image";
import student from "../images/student/studentdiscount.png";
import click from "../images/student/click.svg";
import register from "../images/student/register.svg";
import discount from "../images/student/discount.svg";
import checkout from "../images/student/checkout.svg";
import mobileVideo from "../images/student/studentMobile25.mp4";
import desktopVideo from "../images/student/studentDesktop25.mp4";
import StudentVerificationCTA from "../components/StudentVerificationCTA";
import queryString from "query-string";
import { Link } from "gatsby";
import { useUser } from "../hooks/useUser";
import { ExternalLink } from "../elements/ExternalLink";
import { ACTIVE_SALE, PRICES, SALE_PRICES, STUDENT_DISCOUNT } from "../utils/constants";

const preVerificationCode = [
  {
    icon: click,
    description: "Click the button above",
  },
  {
    icon: register,
    description: "Verify your student email address",
  },
  {
    icon: discount,
    description: "Copy discount code",
  },
  {
    icon: checkout,
    description: "Enter discount code at checkout",
  },
];

const postVerificationCode = [
  {
    icon: discount,
    description: "Copy discount code",
  },
  {
    icon: checkout,
    description: "Enter discount code at checkout",
  },
];

const SubCard = ({ frequency, saving, originalPrice, discountPrice, accumalated = false }) => {
  const { setIsStudent } = useUser();
  return (
    <a onClick={() => setIsStudent(true)} href="https://app.leanwithlilly.com/subscribe" style={{ width: "100%" }} state={{ student: true }}>
      <Flex
        bg="teal"
        borderRadius="7px"
        color="white"
        flex={1}
        py={2}
        px={3}
        mb={[3, 3, 0]}
        mx={[0, 0, 3]}
        flexDirection="column"
        fontWeight={600}
        cursorPointer
        css={css({
          "&:hover": {
            bg: "#38d3d6",
          },
        })}
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Text fontSize={22} textTransform="uppercase">
            {frequency}
          </Text>

          <div>
            <Text fontSize={14}>{saving}% SAVINGS</Text>
            <Text fontSize={10}>{accumalated ? "(Accumulated)" : ""}</Text>
          </div>
        </Flex>

        <Box my={3}>
          <Flex alignItems="flex-end">
            <Text mr={2} lineThrough fontWeight={400}>
              {originalPrice}
            </Text>

            <Text mb={-1} fontSize={24}>
              {discountPrice}
            </Text>
          </Flex>

          <Text textAlign="left" fontSize={12} fontWeight={500}>
            Billed {frequency}
          </Text>
        </Box>

        <Flex justifyContent="space-between">
          <Text color="white" fontWeight={600}>
            SUBSCRIBE NOW
          </Text>

          <CgArrowLongRight size={20} />
        </Flex>
      </Flex>
    </a>
  );
};

const StudentPage = () => {
  const search = typeof window !== "undefined" && window.location.search;

  const { code: discountCode } = queryString.parse(search);
  const studentPrice = key => `£${(Math.ceil(parseFloat(ACTIVE_SALE ? SALE_PRICES[key] : PRICES[key]) * 0.75 * 100) / 100).toFixed(2)}`;

  console.log("CODE", discountCode);

  return (
    <>
      {/* HERO SECTION */}
      <Box>
        {/* MOBILE IMAGE  */}
        <Box display={["block", "block", "none"]}>
          <video autoPlay playsInline loop muted style={{ display: "block", width: "100%" }}>
            <source src={mobileVideo} type="video/mp4" />
          </video>
        </Box>

        {/* DESKTOP IMAGE  */}
        <Box display={["none", "none", "block"]}>
          {" "}
          <video autoPlay playsInline loop muted style={{ display: "block", width: "100%" }}>
            <source src={desktopVideo} type="video/mp4" />
          </video>
        </Box>
      </Box>

      <Flex
        px={3}
        py={5}
        flexDirection="column"
        maxWidth="1000px"
        mx="auto"
        textAlign="center"
        justifyContent="center"
        alignItems="center"
      >
        <Box>
          {" "}
          <Heading fontSize={[22, null, 36]}>STUDENT DISCOUNT</Heading>
          <Text my={2} fontSize={[null, null, 20]}>
            GET 25% OFF ALL LEAN APP SUBSCRIPTIONS
          </Text>
        </Box>
        {!!discountCode ? (
          <div>
            <div
              style={{
                fontSize: "1.5rem",
                margin: "0 0 .5rem 0",
              }}
            >{`Your student discount code:`}</div>
            <span
              style={{
                backgroundColor: "#2db3b6",
                color: "white",
                padding: "0.5rem 1rem",
                fontSize: "2rem",
              }}
            >
              <b>{discountCode}</b>
            </span>
          </div>
        ) : (
          <StudentVerificationCTA />
        )}

        <ExternalLink
          mt={4}
          underline
          color="black"
          fontSize={16}
          href="https://support.leanwithlilly.com/hc/en-us/articles/4407774081298-I-ve-entered-my-student-email-address-but-haven-t-received-a-discount-code"
          target="_blank"
          rel="noopener noreferrer"
        >
          Need help? Visit FAQs
        </ExternalLink>
      </Flex>

      {/* HOW TO SECTION */}
      <Box bg="#879cdb" py={[4, 4, 5]} px={3}>
        <Box color="white" mx="auto" textAlign="center" mb={4}>
          <Heading fontSize={[24, null, 35]}>HOW TO GET YOUR STUDENT DISCOUNT</Heading>
          <Text>It’s sooo simple.</Text>
        </Box>

        <Flex
          flexDirection={["column", null, "row"]}
          justifyContent={["center", null, "space-evenly"]}
          alignItems="center"
          maxWidth="1200px"
          mt={50}
          mx="auto"
        >
          {(discountCode ? postVerificationCode : preVerificationCode).map(({ icon, description }) => (
            <Box key={description} mb={[4, 4, 0]} textAlign="center">
              <Image src={icon} style={{ margin: "auto", marginBottom: 10 }} />
              <Text color="white">{description}</Text>
            </Box>
          ))}
        </Flex>
      </Box>

      {/* SUB CARD SECTION  */}
      <Flex px={3} py={[4, 4, 5]} flexDirection="column" textAlign="center">
        <Heading fontSize={[18, null, 30]} mb={3} maxWidth="1000px" mx="auto">
          I HAVE MY DISCOUNT CODE, AND I’M READY TO BUY MY STUDENT MEMBERSHIP!
        </Heading>

        <Flex
          mx="auto"
          flexDirection={["column", "column", "row"]}
          width="100%"
          maxWidth="1100px"
          justifyContent="space-between"
        >
          <SubCard frequency="Monthly" originalPrice={`£${ACTIVE_SALE ? SALE_PRICES.monthlyPrice : PRICES.monthlyPrice}`} discountPrice={studentPrice('monthlyPrice')} saving={STUDENT_DISCOUNT} />

          <SubCard frequency="Quarterly" originalPrice={`£${ACTIVE_SALE ? SALE_PRICES.quarterlyPrice : PRICES.quarterlyPrice}`} discountPrice={studentPrice('quarterlyPrice')} saving={STUDENT_DISCOUNT} />

          <SubCard frequency="Annually" originalPrice={`£${ACTIVE_SALE ? SALE_PRICES.yearlyPrice : PRICES.yearlyPrice}`} discountPrice={studentPrice('yearlyPrice')} saving={STUDENT_DISCOUNT} />
        </Flex>
      </Flex>

      {/* PRODUCT SECTION  */}
      <Flex flexDirection="column" px={3}>
        <Text mb={4} textAlign="center" fontSize={14} underline mx="auto">
          T&Cs apply etc. Discount is for life across all subscription types. Discount code is one time use only and
          cannot be shared.{" "}
        </Text>
      </Flex>
    </>
  );
};

export default StudentPage;
